@import "src/styles/variables";

.plugin-modal-description {
  display: flex;
  justify-content: space-between;

  &__main-info {
    margin-right: 40px;
    width: calc(100% - 370px);
    padding: 0 15px;

    &__header {
      display: flex;
    }

    &__name {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 5px;
      word-break: break-word;
    }

    .plug-in-card-logo {
      width: 100px;
      height: 100px;
      margin-right: 10px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
      background-color: $white;
    }

    .plug-in-rating {
      display: flex;
      align-items: center;
      font-weight: 700;

      svg {
        fill : $light-blue;
        margin-right: 7px;
      }
    }

    .description-label {
      display: block;
      margin: 25px 0 15px 0;
    }
  }

  .plugin-modal-card {
    background-color: $blue-background-selected;
    border-radius: $border-radius;
    padding: 40px;
    max-width: 370px;

    div {
      margin-bottom: 20px;
    }
    div:last-child {
      margin: 0;
    }
  }
}

@media screen and (max-width: 850px) {
  .plugin-modal-description {
    flex-wrap: wrap;
    &__main-info {
      width: calc(100% - 60px);
      margin-right: 0;
    }
    .plugin-modal-card {
      width: calc(100% - 60px);
      padding: 15px;
      margin-top: 15px;
    }
  }
}