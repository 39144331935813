@import "src/styles/variables";

.timezone-select {
  &.app-select {
    cursor: pointer;

    .MuiAutocomplete-endAdornment {
      margin-right: 10px;
    }

    .MuiInputBase-input, .MuiInputBase-root {
      cursor: pointer;
    }
  }
}