$navigation-pane-width: 350px;
$border-radius: 30px;
$white: #ffffff;
$black: #000000;
$light-grey: #f2f2f2;
$dark-grey: #6b7780;
$red: #f65364;
$light-blue: #3887fc;
$dark-blue: #0b1178;
$blue-background-selected: rgba($dark-blue, 0.1);
$red-background-selected: rgba($red, 0.1);
$box-shadow: 0 0 14px 2px #00000012;
$green: #18A838;
