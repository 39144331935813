.app-loader {
  position: absolute;
  z-index: 1000;
  inset: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;
}
