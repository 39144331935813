@import "src/styles/variables";

.plug-in-card {
  background-color: $white;
  padding: 30px 40px;
  width: 310px;
  border-radius: $border-radius;
  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .plug-in-card-header {
    display: flex;
    align-items: start;
    .MuiBox-root {
      width: calc(100% - 100px);
    }
  }

  .plug-in-card-logo {
    background-color: $light-grey;
    height: 80px;
    width: 80px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 20px;
  }

  .card-name {
    font-size: 1.8em;
    font-weight: 700;
    margin-bottom: 5px;
    word-break: break-word;
  }

  .card-author {
    font-weight: 700;
    font-size: 1.01em;
    margin-bottom: 5px;
    font-weight: 700;
  }

  .card-short-description {
    margin: 15px 0;
    word-break: break-word;
  }

  .plug-in-rating{
    display: flex;
    align-items: center;
    font-weight: 700;

    svg {
      fill : $light-blue;
      margin-right: 7px;
    }
  }

  .plug-in-card-last-update {
    font-size: 0.9em;
  }

  .plug-in-card-more-details {
    color: $dark-blue;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 5px;
    margin-right: 10px;
  }

  .footer-wrapper-for-plug-in-card {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .plug-in-card-more-details {
      box-shadow: none;
      background-color: transparent;
      border-radius: $border-radius;
      padding: 5px 10px;
    }

    .plug-in-buttons {
      &.flex {
        display: flex;
        flex-direction: column;

        .plug-in-card-more-details {
          width: fit-content;
          margin-left: auto;
          margin-top: 8px;
        }
      }
    }
  }

  .plug-in-install-info {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    font-weight: 500;
  }

  .MuiButton-root.Mui-disabled.plugin-btn{
    color:$white;
    background-color: rgba(11, 17, 120, 0.1);
    pointer-events: all;
    &:hover {
      cursor:not-allowed;
    }
  }

  .plugin-btn {
    background-color: $dark-blue;
    color:$white;
    font-weight: 700;

    &:not(.MuiButton-root.Mui-disabled.plugin-btn) {
      &:hover{
        background-color:rgba(11, 17, 120, 0.9);
        box-shadow: 0 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
      }
    }

    &.MuiButton-outlined {
      border-color: transparent;
    }
  }
}

.installed-plug-in-card {
  background-color: $blue-background-selected;

  .plug-in-card-logo{
    background-color: $white;
  }
}

@media screen and (max-width: 1200px) {
  .plug-in-card {
    padding: 15px;

    .card-short-description {
      margin: 10px 0;
      font-size: 15px;
    }

    .plug-in-card-logo {
      width: 60px;
      height: 60px;
      margin-right: 10px;

      svg {
        width: 40px;
      }
    }

    .plug-in-rating {
      font-size: 15px;
    }
  }
}