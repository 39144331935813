@import "src/styles/variables";

.image {
  border-radius: $border-radius;
  object-fit: cover;
  overflow: hidden;
  background-color: rgba($dark-grey, .1);

  img {
    height: 100%;
    width: auto;
  }
}