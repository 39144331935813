@import "src/styles/variables";

.app-modal {
  & .MuiPaper-root {
    padding: 64px 100px;
    border-radius: $border-radius;
    position: relative;
    max-height: 70vh;
    min-width: 350px;

    & .close-icon {
      position: absolute;
      top: 42px;
      right: 50px;
      cursor: pointer;
      height: 22px;
    }
  }

  & .MuiDialogContent-root {
    padding: 0;
    padding-top: 1%;
  }

  & .MuiBackdrop-root {
    background-color: rgba(black, .85);
  }

  & h2.MuiTypography-root {
    padding: 0;
    font-weight: 700;
    font-size: 30px;
  }

  & .MuiDialogActions-root {
    padding: 0;
    margin-top: 20px;

    & .MuiButtonBase-root {
      border-radius: $border-radius;
      padding: 9.5px 20px;
      color: white;
      font-weight: 700;
    }

    .footer {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    .error {
      font-size: 14px;
      color: $red;
    }
  }

  & .info {
    height: 22px;
    color: $light-blue;
  }


  & *::-webkit-scrollbar {
    width: 6px;
    height: 6px;

    &-track {
      background-color: white;
      border-radius: $border-radius;
    }

    &-thumb {
      border-radius: $border-radius;
    }
  }
}

.blue {
  &.app-modal .MuiDialogActions-root .MuiButtonBase-root:not(.Mui-disabled) {
    background-color: $dark-blue;
  }

  ::-webkit-scrollbar {
    &-thumb {
      background: $dark-blue;
    }
  }
}

.red {
  &.app-modal .MuiDialogActions-root .MuiButtonBase-root:not(.Mui-disabled) {
    background-color: $red;
  }

  ::-webkit-scrollbar {
    &-thumb {
      background: $red;
    }
  }
}

@media screen and (max-width: 850px) {
  .app-modal {
    & .MuiPaper-root {
      padding: 30px;

      & .close-icon {
        top: 30px;
        right: 30px;
      }
    }
  }
}