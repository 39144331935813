@import "src/styles/fonts";

body,
html {
  margin: 0;
  font-family: 'Neue Haas Grotesk';
  font-size: 17px;
}

.MuiTooltip-popper {
  z-index: 1500 !important;
}
