@import "src/styles/variables";

.confirm-modal {
  &__icon {
    display: inline-block;
    margin-right: 10px;
  }

  & .MuiPaper-root {
    border-radius: $border-radius;
    padding: 24px;

    .MuiButtonBase-root {
      border-radius: 60px;
      padding: 10px 20px;
    }
  }

  &.blue {
    .MuiButton-outlined {
      color: $dark-blue;
      border-color: $dark-blue;

      &:hover {
        background: $blue-background-selected;
      }
    }

    .MuiButton-contained {
      background: $dark-blue;
    }
  }

  &.red {
    .MuiButton-outlined {
      color: $red;
      border-color: $red;

      &:hover {
        background: $red-background-selected;
      }
    }

    .MuiButton-contained {
      background: $red;
    }
  }
}
