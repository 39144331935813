@import "src/styles/variables";

.account-menu {
  position: relative;

  & .MuiButtonBase-root {
    padding: 0;
    color: #000;

    &:hover {
      background: none;
    }
  }

  &__logo {
    font-size: 15px;

    & .MuiAvatar-root {
      width: 48px;
      height: 48px;
      margin-right: 12px;
    }

    svg {
      margin-left: 8px;
    }

    .name-wrapper {
      display: flex;
      flex-direction: column;
    }

    .role {
      display: inline-block;
      font-size: 12px;
      font-weight: bold;
      color: $red;
      width: fit-content;
      padding: 4px 8px;
      background-color: rgba($red, .1);
      border-radius: $border-radius;
      margin-top: 4px;
    }
  }

  &__menu {
    &-header {
      position: relative;

      display: flex;
      align-items: center;

      padding: 24px 37px 19px;

      text-decoration: none;

      border-bottom: 2px solid $light-grey;

      .close-icon {
        position: absolute;
        top: 24px;
        right: 24px;
        color: #000;
        height: 18px;

        &:hover {
          cursor: pointer;
        }
      }

      & .MuiAvatar-root {
        width: 76px;
        height: 76px;
        margin-right: 12px;
      }

      &-info {
        width: 100%;
        min-width: 0;

        .name {
          font-weight: 700;
          color: #000;
        }

        .email {
          overflow: hidden;
          color: $red;
          text-overflow: ellipsis;
          letter-spacing: 1px;
        }
      }
    }

    &-timezone {
      display: none;
      padding: 19px 24px;
      border-bottom: 2px solid #f2f2f2;

      & > div {
        flex-grow: 1;
      }

      &-select.app-select.MuiFormControlLabel-root {
        margin-top: 10px;
        width: 100%;

        span.MuiTypography-root {
          display: none;
        }

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;

          &-track {
            background-color: $white;
            border-radius: $border-radius;
          }

          &-thumb {
            border-radius: $border-radius;
            background: $red;
          }
        }
      }

      .language-select {
        min-width: 130px;
        margin-left: 15px;
        align-self: flex-end;
      }
    }

    &-services {
      padding: 19px 24px;
      border-bottom: 2px solid $light-grey;

      &-wrapper {
        margin-top: 10px;
        margin-bottom: 20px;
        max-height: 300px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;

          &-track {
            background-color: $white;
            border-radius: $border-radius;
          }

          &-thumb {
            border-radius: $border-radius;
            background: $red;
          }
        }
      }

      &-manage {
        text-decoration: none;
        color: $dark-grey;

        &:hover {
          color: $red;
        }
      }
    }

    .MuiMenu-paper {
      min-width: 340px;
      max-width: 450px;
      border-radius: 30px;

      .MuiMenu-list {
        padding: 0;
      }
    }

    &-links {
      padding: 24px 37px;

      &-item {
        &.MuiButtonBase-root {
          padding: 0;

          &:hover {
            background: none;
          }

          &:not(:last-child) {
            padding-bottom: 24px;
          }
        }

        .link {
          margin-left: 25px;
        }

        &:hover p {
          font-weight: 700;
        }

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .account-menu {
    &__menu-timezone {
      display: flex;

      .language-select {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .account-menu {
    &__menu-timezone {

      .language-select {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .account-menu {
    margin-left: 0;

    &__menu-timezone {
      display: none;
    }
  }
}