@import "src/styles/variables";

.links {
  &__item {
    margin-bottom: 4px;
    padding: 13px 0;
    border-radius: $border-radius;

    & .MuiListItemIcon-root {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-right: 25px;

      svg {
        height: 23px;
        max-width: 27px;
        max-height: 25px;
        color: $black;
      }

      .expand-icon {
        width: 12px;
        height: auto;
        margin-left: 5px;
        opacity: 1;
        transition: opacity .4s ease-in-out;

        path {
          fill: $black;
        }

        &:hover {
          opacity: 0;
        }

        &.arrow-up {
          transform: rotate(180deg);
        }
      }
    }

    li {
      padding: 0 30px;
    }

    &.selected {
      background-color: white;

      .MuiTypography-root {
        font-weight: 700;
      }
    }

    & .MuiListItemButton-root {
      display: flex;
      padding: 0;

      & .MuiTypography-root {
        display: flex;
        flex-grow: 1;
        align-items: center;
      }

      &:hover {
        background: none;
      }
    }

    &.expanded,
    &.selected-parent {
      overflow: hidden;
      background-color: white;

      & > li .MuiTypography-root {
        font-weight: 700;
      }

      & .selected {
        border-radius: 0;
      }
    }

    & .MuiCollapse-root {
      & .links__item {
        padding-right: 0;
        padding-left: 50px;
      }

      & .MuiListItemIcon-root {
        margin-right: 15px;
        justify-content: center;
      }
    }
  }

  & .selected-parent.views-group {
    & .selected {
      background-color: $red-background-selected;

      span,
      svg {
        font-weight: 700;
        color: $red;
      }

      path {
        fill: $red;
      }
    }
  }

  & .selected-parent.admin-group {
    & .selected {
      background-color: $blue-background-selected;

      span,
      svg {
        font-weight: 700;
        color: $dark-blue;
      }

      path {
        fill: $dark-blue;
      }
    }
  }

  &__nested {
    & .links__item:first-child {
      margin-top: 13px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .links {
    &__item {
      & .MuiListItemIcon-root {
        margin-right: 15px;
      }

      & .MuiCollapse-root {
        & .MuiListItemIcon-root {
          margin-right: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .links {
    .MuiCollapse-wrapperInner {
      margin: 15px 0;
    }

    &__item {
      margin: 0 25px 4px 25px;

      li {
        padding: 0 25px;
      }

      & .MuiCollapse-root {
        & .links__item {
          margin: 0;
          padding: 13px 20px 13px 40px;
        }
      }
    }
  }
}