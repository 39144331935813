@import "src/styles/variables";

.app {
  display: flex;
  justify-content: space-between;
  height: fit-content;
  min-height: 100vh;

  &__main {
    display: flex;
    flex-direction: column;
    width: calc(100% - $navigation-pane-width);
    min-width: 350px;

    & .content {
      flex-grow: 1;
    }
  }

  & .blue {
    height: 100%;
    background-color: $blue-background-selected;

    .MuiButtonBase-root.app-next-btn,
    .MuiButtonBase-root.add-filter-btn:not(.Mui-disabled) {
      background-color: $dark-blue;
    }

    .MuiButtonBase-root.app-step-btn {
      color: $dark-blue
    }

    .stepper-body .Mui-disabled.app-next-btn {
      background-color: rgba(11, 17, 120, 0.1);
      cursor: no-drop;
    }
  }

  & .red {
    height: 100%;
    background-color: $red-background-selected;

    .MuiButtonBase-root.app-next-btn,
    .MuiButtonBase-root.add-filter-btn {
      background-color: $red;
    }

    .MuiButtonBase-root.app-step-btn {
      color: $red
    }
  }

  & .header.blue,
  & .header.red {
    background-color: $white;
  }

  .wrapper {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
}

.form {
  padding: 60px 65px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .submit-btn {
      padding: 9.5px 20px;
      color: white;
      font-weight: 600;
      border-radius: $border-radius;
      margin-left: auto;
      line-height: 25.5px;
      font-size: 17px;
      letter-spacing: .5px;

      &:not(.Mui-disabled) {
        background-color: $green;
      }
    }

    .cancel-btn {
      color: $dark-blue;
      font-weight: 700;
      font-size: 17px;

      &:hover {
        text-decoration: underline;
        background-color: transparent;
      }
    }
  }

  &__fields {
    width: 70%;
    display: flex;
    flex-direction: column;

    & > div {
      width: 100%;
      display: flex;
      padding-bottom: 20px;

      & label, .checkbox-wrapper {
        width: 47%;

        & > div {
          width: 100%;
        }
      }
    }
  }

  & .MuiFormControlLabel-root {
    flex-grow: 1;

    & .MuiFormControl-root {
      width: 100%;
    }
  }

  .app-input {
    margin-right: 26px;
  }

  & .MuiFormLabel-root {
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 700;
    color: black;
  }

  .app-table .main {
    margin: 0;
  }
}

@media screen and (max-width: 850px) {
  .app {
    margin-top: -1px;

    &__main {
      width: calc(100%);
    }
  }
}