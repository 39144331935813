@import "src/styles/variables";

.app-select {
  &.MuiFormControlLabel-root {
    text-align: start;
    margin: 0;
    align-self: start;

    & > .MuiStack-root,
    & > div:not([class]),
    & > .MuiFormControlLabel-label {
      width: 100%;
      padding-bottom: 10px;
      font-weight: 700;

      & .MuiTypography-root {
        font-weight: 700;
      }
    }

    & span.MuiTypography-root {
      font-size: 20px;

      &.Mui-disabled ~ span.MuiFormControlLabel-asterisk {
        color: rgba($dark-grey, .8);
      }
    }
  }

  .MuiInputBase-root, .MuiAutocomplete-root {
    width: 100%;
    align-items: start;
    border-radius: $border-radius;
    background-color: $light-grey;
    position: relative;

    .MuiSelect-select, .MuiInputBase-root {
      padding: 18px 18px 18px 43px;

      & > .MuiInputBase-input {
        padding: 0;
      }
    }

    &.MuiInputBase-readOnly {
      & ~ .MuiStack-root {
        cursor: auto;
      }
    }

    & .MuiInputBase-readOnly {
      cursor: auto;
    }

    &.Mui-focused:not(.MuiInputBase-readOnly) {
      border-radius: $border-radius $border-radius 0 0;

      & fieldset {
        border-color: transparent;
      }

      .MuiSelect-icon {
        transform: rotate(180deg);
      }
    }

    &.Mui-focused:has([aria-expanded="false"]):not(.Mui-expanded) {
      border-radius: $border-radius;

      .MuiSelect-icon {
        transform: rotate(0deg);
      }
    }
    
    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }

    & .MuiAutocomplete-endAdornment {
      right: 18px !important;
    }

    .MuiSelect-icon {
      position: absolute;
      top: 22px;
      right: 18px;
      min-height: 21px;
      color: $dark-grey;

      &.hidden {
        display: none;
      }
    }

    .remove {
      position: absolute;
      top: 22px;
      right: 46px;
      min-height: 20px;
      color: $dark-grey;
      cursor: pointer;

      &.hidden {
        display: none;
      }
    }

    .MuiAutocomplete-popupIndicator {
      &:hover {
        background: none;
      }

      svg {
        max-width: 17px;
      }
    }

    .tags-wrapper {
      & ~ input {
        margin: auto;
      }
      max-height: 70px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }
    }
  }

  &.freeSolo {
    .MuiInputBase-root, .MuiAutocomplete-root {
      align-items: center;
    }

    .MuiAutocomplete-inputFocused {
      font-weight: 500;
      color: $red;
    }
  }

  &.input-grey {
    .MuiInputBase-root, .MuiAutocomplete-root {
      background-color: $light-grey;
    }
  }
}

.MuiAutocomplete-popper {
  transform: translate3d(64.5818px, 224, 0px);

  &[data-popper-placement="top"] {
    & > .MuiPaper-root {
      border-radius: 30px 30px 0 0;
    }
  }
}

.MuiMenu-paper.app-select__paper,
.MuiAutocomplete-popper > .MuiPaper-root {
  box-sizing: border-box;
  border-radius: 0 0 $border-radius $border-radius;
  box-shadow: 0px 0px 14px 2px rgba(0, 0, 0, 0.07);
  overflow-y: hidden;

  .MuiList-root, ul.app-select-search {
    background-color: white;
    max-height: 180px;
    overflow-y: auto;
    padding: 0;
    position: relative;

    .MuiMenuItem-root {
      padding: 10px 33px;
      color: $dark-grey;
      white-space: unset;
      word-break: break-all;

      &.Mui-selected,
      &[aria-selected="true"] {
        background-color: rgba($dark-blue, .1);
        color: black;
      }

      &.Mui-focused:not([aria-selected="true"]) {
        background-color: rgba($dark-grey, .1);
      }
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      position: absolute;
  
      &-track {
        background-color: white;
        border-radius: $border-radius;
      }
    
      &-thumb {
        border-radius: $border-radius;
        background: $dark-blue;
      }
    }

    &.blue {
      .MuiMenuItem-root {
        &.Mui-selected,
        &[aria-selected="true"] {
          background-color: rgba($dark-blue, .1);
          color: black;
        }

        &.Mui-focused:not([aria-selected="true"]) {
          background-color: rgba($dark-grey, .1);
        }
      }

      &::-webkit-scrollbar {
        &-thumb {
          background: $dark-blue;
        }
      }
    }

    &.red {
      .MuiMenuItem-root {
        &.Mui-selected,
        &[aria-selected="true"] {
          background-color: rgba($red, .1);
          color: black;
        }
      }

      &::-webkit-scrollbar {
        &-thumb {
          background: $red;
        }
      }
    }
  }

  &.red {
    .MuiList-root {
      .MuiMenuItem-root {
        padding: 10px 33px;
        color: $dark-grey;
        white-space: unset;
        word-break: break-all;
  
        &.Mui-selected,
        &[aria-selected="true"] {
          background-color: rgba($red, .1);
          color: black;
        }
      }

      &::-webkit-scrollbar {
        &-thumb {
          background: $red;
        }
      }
    }
  }
}

.view-user-page {
  .app-select {
    .MuiInputBase-root, .MuiAutocomplete-root {
      background-color: $white;
      .MuiSelect-select, .MuiInputBase-root {
        padding: 10px 18px 10px 43px;
      }

      .MuiSelect-icon {
        top: 10px;
      }
    }
  }
}