@import "src/styles/variables";

.app-input {
  margin: 0;

  & > div {
    width: 100%;
  }

  & .MuiFormControl-root {
    margin-bottom: 0;
    width: 100%;
    margin-top: 10px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiInputBase-root {
    background: white;
    border-radius: $border-radius;
    border: none;
    padding: 18px 43px;

    & .MuiInputBase-input {
      padding: 0;
      border-radius: 0;

      &:-webkit-autofill {
        box-shadow: inset 0 0 20px 20px white;
      }
    }
  }

  &.input-grey {
    & .MuiInputBase-root {
      background: $light-grey;

      & .MuiInputBase-input {
        &:-webkit-autofill {
          box-shadow: inset 0 0 20px 20px $light-grey;
        }
      }
    }
  }

  & .MuiStack-root,
  & > .MuiTypography-root {
    width: 100%;
  }

  & span.MuiTypography-root p {
    font-size: 20px;
    font-weight: 700;
    line-height: unset;
  }

  & span.MuiFormControlLabel-asterisk {
    font-weight: 700;
  }

  &__label {
    display: flex;
    align-items: center;

    & .info-icon {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        padding: 0;
      }

      svg {
        color: $light-blue;
        width: 20px;
        height: 20px;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.blue {
  .app-input {
    & .Mui-focused {
      .MuiInputBase-input {
        font-weight: 700;
        color: $dark-blue;

        &:-webkit-autofill {
          -webkit-text-fill-color: $dark-blue;
        }
      }

      & .MuiInputBase-root {
        &.Mui-focused fieldset {
          border: none;
        }
      }
    }

    &.input-grey {
      & .Mui-focused {
        .MuiInputBase-input {
          color: $dark-grey;

          &::before {
            background: $dark-grey;
          }

          &:focus {
            color: $dark-blue;
          }
        }
      }
    }
  }
}

.red {
  .app-input {
    & .Mui-focused {
      .MuiInputBase-input {
        color: $red;
        font-weight: 700;
      }
    }

    & .MuiInputBase-root {
      &.Mui-focused fieldset {
        border-color: $red;
      }
    }
  }
}
