@import "src/styles/variables";

.breadcrumb {
  .MuiBreadcrumbs-li {
    font-size: 15px;
  }

  &__item,
  &__item.MuiTypography-root {
    color: $dark-grey;
    text-decoration: none;

    &.selected {
      font-weight: 700;
      font-size: 15px;
    }
  }

  &__back-btn {
    margin-right: 29px;

    path {
      fill: $dark-grey;
    }

    &:hover {
      cursor: pointer;
    }
  }
}


@media screen and (max-width: 950px) {
  .breadcrumb__back-btn {
    margin-right: 5px;
  }
}
