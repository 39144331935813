@import "src/styles/variables";

.navigation-pane {
  & .MuiDrawer-paper {
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
    width: $navigation-pane-width;
    padding: 55px 20px;
    z-index: 1;

    background-color: $light-grey;

    & .nav-wrapper > svg {
      width: fit-content;
      margin-bottom: 44px;
      padding-left: 30px;
    }
  }

  & .MuiPaper-root::-webkit-scrollbar {
    display: none;
  }
  & .MuiPaper-root {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@media screen and (max-width: 850px) {
  .navigation-pane {
    & .MuiDrawer-paper {
      display: none;
    }
  }
}
