@import "src/styles/variables";

.app {
  .header.red {
    height: inherit;

    h1 {
      color: $red;
    }
  }

  .header.blue {
    height: inherit;

    h1 {
      color: $dark-blue;
    }
  }
}

.header {
  height: auto;

  .mobile-header {
    display: none;
  }

  &__header {
    display: flex;
    align-items: center;

    padding: 14px 65px 14px 29px;

    border-bottom: 2px solid#F2F2F2;

    &-buttons {
      display: flex;
      align-items: center;

      &-item.MuiIconButton-root {
        padding: 13px;
        margin-right: 15px;
        color: #000;
        background-color: rgb(246 83 100 / 20%);

        &:hover {
          background-color: rgb(246 83 100 / 30%);
        }
      }
    }

    &-timezone.app-select {
      display: inline-block;
      margin-left: auto;
      margin-right: 15px;
      width: 300px;

      &.MuiFormControlLabel-root span.MuiTypography-root {
        display: none;
      }

      .MuiAutocomplete-root .MuiInputBase-root {
        padding: 14px 18px 14px 43px;
      }
    }
    
    & .language-select.app-select {
      display: inline-block;
      margin-right: 15px;
      width: 100px;

      &.MuiFormControlLabel-root span.MuiTypography-root {
        display: none;
      }

      .MuiInputBase-root .MuiSelect-select {
        padding: 14px 18px 14px 30px;
      }

      .fa-angle-down {
        top: 16px;
        cursor: pointer;
      }
    }
  }

  &__main {
    display: flex;
    padding: 30px 65px;

    &:has(.plug-in-rating) {
      align-items: baseline;
    }

    h1 {
      font-size: 50px;
      font-weight: 700;
      line-height: 55px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .MuiIconButton-root:hover {
      background: none;
    }

    & .plug-in-rating {
      margin: 0 20px;
      font-weight: 700;
      font-size: 19px;

      svg {
        fill: $light-blue;
        margin-right: 10px;
      }
    }
  }

  .info {
    color: $light-blue;
  }
}

@media screen and (max-width: 1350px) {
  .header {
    &__header {
      &-timezone.app-select {
        display: none;
      }

      & .language-select.app-select  {
        margin-left: auto;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .header {
    &__header {
      &-buttons {
        margin-left: auto;
      }

      & .language-select.app-select  {
        display: none;
      }
    }

    .mobile-header {
      .account-mobile-menu {
        .language-select.app-select {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .header {
    .mobile-header {
      padding: 25px;
      display: initial;

      &_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 20px;

        svg {
          width: 160px;
        }
      }

      &_menu {
        background-color: $light-grey;
        padding: 20px 0;
      }

      &_menu.close {
        display: none;
      }

      &_menu.open {
        display: block;
      }

      .header__header-buttons {
        flex-direction: column;
        align-items: start;
        font-size: 16px;

        .MuiButtonBase-root, .MuiSelect-icon {
          margin-right: 12px;

          &.account-menu__logo {
            font-size: 16px;
          }
        }
      }

      .account-mobile-menu {
        background-color: $white;
        border-radius: $border-radius;
        padding: 15px;
        margin: 0 25px;
        display: block;

        .MuiBox-root {
          margin-bottom: 10px;
        }

        .timezone-wrapper {
          display: flex;

          .header__header-timezone {
            display: block;
            width: 100%;
            flex-grow: 1;
          }

          .language-select {
            min-width: 150px;
            width: fit-content;

            &.app-select .MuiInputBase-root {
              & .MuiSelect-select {
                padding: 14px 18px 14px 43px;
              }

              & .MuiSelect-icon {
                top: 17px;
                min-height: 20px;
              }
            }
          }
        }
      }
    }

    &__header {
      padding: 0 20px;
      border: none;
      justify-content: left;

      &-buttons {
        display: none;
      }
    }

    &__main {
      padding: 25px;

      h1 {
        font-size: 30px;
        line-height: 33px;
      }
    }

    .info {
      font-size: 17px;
    }
  }
}